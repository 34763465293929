import { apiClient, getSafetyApiToken } from '@zep/utils/api.ts';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SERVER_BASEURL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    ...(getSafetyApiToken() && {
      Authorization: `Bearer ${getSafetyApiToken()}`,
    }),
  },
});
AXIOS_INSTANCE.interceptors.response.use(res => {
  if (res.status === 200 && res.data.status !== 'SUCCESS') {
    throw new ZepQuizApiError(res.data.status || '', res.data);
  }

  return res;
});

AXIOS_INSTANCE.interceptors.request.use(
  config => {
    if (config.params && config.params.request) {
      const requestParams = config.params.request;

      if (requestParams) {
        config.params = {
          ...config.params,
          ...requestParams,
        };
        delete config.params.request;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const setHttpClientToken = (token: string) => {
  apiClient.setToken(token);
  AXIOS_INSTANCE.defaults.headers['Authorization'] = `Bearer ${token}`;
};

export class ZepQuizApiError extends Error {
  public details?: any;

  constructor(message: string, details?: any) {
    super(message);
    this.name = 'QuizApiError';
    this.details = details;
    Object.setPrototypeOf(this, ZepQuizApiError.prototype);
  }
}

// use your own URL here or environment variable

// add a second `options` argument here if you want to pass extra options to each generated query
export const httpsInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): CancellablePromise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data) as CancellablePromise<T>;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
