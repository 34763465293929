import { Button } from '@zep/ui';
import { useRouter } from 'next/router';

export const ErrorBoundaryFallback = () => {
  const router = useRouter();
  return (
    <div className={'flex w-full flex-col items-center gap-xs'}>
      알 수 없는 오류가 발생했습니다. 페이지를 새로고침하거나, 잠시 후 다시
      시도해 주세요.
      <br />( An unknown error has occurred. Please refresh the page or try
      again later.)
      <Button onClick={() => router.isReady && router.reload()}>
        새로고침 (Reload)
      </Button>
    </div>
  );
};
